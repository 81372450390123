import React from "react";
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa'; // 메뉴 아이콘을 위한 임포트

const Logo = ({ toggleMenu }) => {
    return (
        <h1 className='header__logo'>
            <Link to='/' onClick={toggleMenu}>
                <span className="logo-text">Arkwith</span>
                <FaBars className="menu-icon" onClick={toggleMenu} /> {/* 메뉴 아이콘 추가 */}
            </Link>
        </h1>
    );
}

export default Logo;
