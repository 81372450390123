import React from "react";

const Footer = () => {
    return (
        <footer id='footer' role="contentinfo">
            <div className="footer-container">
                <div className="company-info">
                    <p>회사명: ㈜아크위드 | 대표: 최정숙</p>
                    <p>주소: 인천광역시 중구 은하수로 351</p>
                    <p>이메일: arkwith7@gmail.com</p>
                </div>
                <div className="legal-links">
                <p>사업자 등록번호: 235-86-01633</p>
                <p>통신판매업 신고번호: 2021-인천중구-0285</p>
                    <a href="/privacy">개인정보 처리방침</a>
                    <br />
                    <a href="/terms">이용약관</a>
                </div>
            </div>
        </footer>
    );

}

export default Footer;
