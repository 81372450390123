import axios from 'axios';
export const BACKEND_API_URL =  process.env.REACT_APP_BACKEND_API_URL;

export default class BackendApi {
    constructor(latency = 500) {
        this.latency = latency;
        this.apiUrl = BACKEND_API_URL; // API 기본 URL 설정
    }

    signIn = async (email, password) => {
        try {
            const response = await axios.post(`${this.apiUrl}api/auth/signin/local`, {
                email, // 객체 단축 속성 사용
                password
            });

            // 백엔드에서 응답으로 'token'을 포함한 객체를 반환하므로, 이를 처리
            const { success, token } = response.data; // 구조 분해 할당을 사용하여 데이터 추출

            if (success) {
                return { success: true, token };
            } else {
                return { success: false, message: '로그인 실패' }; // 실패 시 메시지 수정
            }
        } catch (error) {
            console.error('로그인 요청 실패:', error);
            return { success: false, message: error.response?.data?.message || '요청오류 또는 이메일, 비밀번호가 잘못 되었습니다.' };
        }
    }

    getLoginUser = async (token) => {
        try {
            const response = await axios.get(`${this.apiUrl}api/auth/me`, {
                headers: {
                    'Accept': '*/*', // MIME 타입을 모든 타입으로 명시
                    'Authorization': `Bearer ${token}` // Bearer 토큰 사용
                }
            });

            // 응답 상태 코드가 200인 경우, 사용자 데이터 반환
            if (response.status === 200) {
                return { error: false, data: response.data };
            } else {
                // 응답 코드가 200이 아닌 경우, 오류 메시지 반환
                return { error: true, message: response.data.message || 'Invalid User Credentials' };
            }
        } catch (error) {
            // 네트워크 오류 또는 서버 오류 처리
            return { error: true, message: error.response?.data?.message || 'Authentication failed' };
        }
    }

    signUp = async (userName, email, password) => {
        try {
            const response = await axios.post(`${this.apiUrl}api/auth/signup`, {
                userName,
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                const { success, token } = response.data; // 구조 분해 할당을 사용하여 데이터 추출

                if (success) {
                    return { success: true, token };
                } else {
                    return { success: false, message: '회원가입 실패' }; // 실패 시 메시지 수정
                }
            } else {
                return { success: false, message: response.data.message || '회원가입 실패' }; // 응답 코드가 200이 아닌 경우, 오류 메시지 반환
            }
        } catch (error) {
            return { success: false, message: error.response?.data?.message || '회원가입 중 오류가 발생했습니다.' }; // 네트워크 오류 또는 서버 오류 처리
        }
    }

 
    // YouTube 비디오의 자막 스크립트 가져오기
    fetchTranscript = async (videoId, token) => {
        try {
            const response = await axios.post(`${this.apiUrl}api/youtube/fetchTranscript`, {
                videoId // POST 요청에 videoId를 바디로 전송
            }, {
                headers: {
                    'Accept': 'application/json', // JSON 응답 명시
                    'Content-Type': 'application/json', // JSON 요청 명시
                    'Authorization': `Bearer ${token}` // Bearer 토큰 사용
                }
            });
            if (response.status === 200) {
                return { success: true, data: response.data.transcript };
            } else {
                return { success: false, message: '자막 스크립트를 가져오는 데 실패했습니다.' };
            }
        } catch (error) {
            return { success: false, message: error.response?.data?.message || '네트워크 오류가 발생했습니다.' };
        }
    }

    // YouTube 비디오의 자막 스크립트를 요약한다
    fetchSummary = async (transcript, languageCode, token) => {
        try {
            const response = await axios.post(`${this.apiUrl}api/youtube/fetchSummary`, {
                transcript, // 자막 스크립트
                languageCode // 언어 코드
            }, {
                headers: {
                    'Accept': 'application/json', // JSON 응답 명시
                    'Content-Type': 'application/json', // JSON 요청 명시
                    'Authorization': `Bearer ${token}` // Bearer 토큰 사용
                }
            });
            if (response.status === 200) {
                return { success: true, data: response.data.summary };
            } else {
                return { success: false, message: '비디오 비디오의 자막 스크립트 요약에 실패했습니다.' };
            }
        } catch (error) {
            return { success: false, message: error.response?.data?.message || '요약 중 오류가 발생했습니다.' };
        }
    }

    // YouTube 비디오 요약
    summarizeVideo = async (videoId, languageCode, token) => {
        try {
            const response = await axios.post(`${this.apiUrl}api/youtube/summarizeVideo`, {
                videoId,
                languageCode
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` // Bearer 토큰 사용
                }
            });
            if (response.status === 200) {
                return { success: true, data: response.data.summary };
            } else {
                return { success: false, message: '비디오 요약에 실패했습니다.' };
            }
        } catch (error) {
            return { success: false, message: error.response?.data?.message || '요약 중 오류가 발생했습니다.' };
        }
    }
}

