import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider, AuthRoute } from './contexts/AuthContext';
import Main from './components/section/Main';
import PrivacyPolicy from './pages/Privacy/PrivacyPolicy';

const Home = lazy(() => import('./pages/Home'));
const Developer = lazy(() => import('./pages/Developer'));
const Youtube = lazy(() => import('./pages/Youtube'));
const Channel = lazy(() => import('./pages/Channel'));
const Video = lazy(() => import('./pages/Video'));
const VideoSummaryPage = lazy(() => import('./pages/videoSummary/VideoSummaryPage'));
const Search = lazy(() => import('./pages/Search'));
const Not = lazy(() => import('./pages/Not'));
const SignIn = lazy(() => import('./pages/auth/SignIn'));
const SignUp = lazy(() => import('./pages/auth/SignUp'));
const UserInfo = lazy(() => import('./pages/auth/UserInfo'));

const App = () => {
  return (
    <BrowserRouter>
        <Suspense fallback={<Main/>}>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/search/:searchId' element={<Search />} />
            <Route path='/video/:videoId' element={<Video />} />
            <Route path='/channel/:channelId' element={<Channel />} />
            <Route path='/videoSummary/:videoId' element={<AuthRoute element={<VideoSummaryPage />} />} />
            <Route path='/developer' element={<AuthRoute element={<Developer />} />} />
            <Route path='/youtube' element={<AuthRoute element={<Youtube />} />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/user-details' element={<AuthRoute element={<UserInfo />} />} />
            <Route path='*' element={<Not />} />
          </Routes>
          </AuthProvider>
        </Suspense>
    </BrowserRouter>
  );
}

export default App;
