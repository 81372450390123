import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import BackendApi from '../utils/backendApi';

const AuthContext = React.createContext();
const GOOGLE_CALL_BACK_URL = process.env.REACT_APP_GOOGLE_CALL_BACK_URL;

const AuthProvider = ({ children }) => {
    const [contextState, setContextState] = useState({
        authorize: false,
        token: null,
        email: null,
        error: null,
    });

    const backendApi = new BackendApi();

    const onLogin = async (email, password) => {
        setContextState(prevState => ({ ...prevState, authorize: false }));
        try {
            const response = await backendApi.signIn(email, password);
            console.log("로그인 response:", response);
            if (response.success) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('email', email);
                setContextState({
                    authorize: true,
                    token: response.token,
                    email: email,
                    error: null,
                });
            } else {
                setContextState(prevState => ({
                    ...prevState,
                    authorize: false,
                    error: response.message,
                }));
            }
            return response.success;
        } catch (error) {
            console.error('로그인 처리 중 오류 발생:', error);
            setContextState(prevState => ({
                ...prevState,
                authorize: false,
                error: '로그인 중 오류가 발생했습니다.'
            }));
            return false;
        }
    };

    const onLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        setContextState({
            authorize: false,
            token: null,
            email: null,
            error: null,
        });
    };

    const onGoogleLogin = () => {
        // localStorage.setItem('preLoginPath', window.location.pathname);
        // console.log("onGoogleLogin preLoginPath:", window.location.pathname);
        window.location.href = GOOGLE_CALL_BACK_URL;
    };

    const updateAuthDetails = (authorize, token, email) => {
        setContextState(prevState => ({
            ...prevState,
            authorize: authorize,
            token: token,
            email: email
        }));
    };

    return (
        <AuthContext.Provider value={{
            ...contextState,
            onLogin,
            onLogout,
            onGoogleLogin,
            updateAuthDetails,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

const AuthRoute = ({ element }) => {
    const { authorize } = useContext(AuthContext);
    const location = useLocation();

    return authorize ? element : <Navigate to="/signin" state={{ from: location }} replace />;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider, AuthRoute };
