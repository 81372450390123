import React from 'react';
import Main from '../../components/section/Main';
import '../../assets/scss/section/_privacypolicy.scss'; // SCSS 파일 임포트

const PrivacyPolicy = () => {
  return (
    <Main 
        title="개인정보 처리방침"
        description="저희 사이트의 개인정보 처리방침 페이지입니다.">
      <div className="privacy-policy">
        <header>
          <div className="privacy-container">
            <h1>개인정보처리방침</h1>
          </div>
        </header>
        <main>
          <div className="privacy-container">
            <section>
              <h2>1. 개인정보의 처리 목적</h2>
              <p>저희 웹사이트는 유튜브 API를 통해 다음과 같은 서비스를 제공합니다:</p>
              <ul>
                <li>유튜브 영상 검색 기능</li>
                <li>영상의 텍스트 변환 서비스 (자동 자막 생성)</li>
                <li>영상 내용 요약 서비스</li>
                <li>영상 내용에 기반한 지식 그래프 표시</li>
              </ul>
              <p>이 서비스들을 제공하기 위해 이용자의 유튜브 검색 키워드와 시청 이력 데이터를 수집 및 처리합니다.</p>
            </section>
            
            <section>
              <h2>2. 개인정보의 수집 및 이용</h2>
              <p>수집하는 개인정보는 다음과 같습니다:</p>
              <ul>
                <li>이메일, 비밀번호 (회원 가입 시)</li>
                <li>서비스 이용 기록, 접속 로그, IP 정보</li>
                <li>유튜브 검색 키워드와 시청 이력</li>
              </ul>
            </section>

            <section>
              <h2>3. 개인정보의 보유 및 이용 기간</h2>
              <p>개인정보는 서비스 이용자의 동의 하에 수집되며, 목적 달성 후 지체 없이 파기합니다. 법령에서 별도의 보관 의무가 있는 경우 해당 법령의 규정에 따라 보관합니다.</p>
            </section>

            <section>
              <h2>4. 개인정보 처리방침의 변경</h2>
              <p>본 방침은 시행일로부터 적용되며, 법령 및 방침 변경 시 변경 내용의 추가, 삭제 및 정정이 있는 경우 변경사항의 시행 7일 전부터 사이트 공지사항을 통하여 고지할 것입니다.</p>
            </section>
          </div>
        </main>
        <footer>
          <div className="privacy-container">
            <p>Arkwith© 2024 지식 컨텐츠 서비스. 모든 권리 보유.</p>
          </div>
        </footer>
      </div>
    </Main>
  );
}

export default PrivacyPolicy;

