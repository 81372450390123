// import { CiBaseball } from "react-icons/ci";
import { CiCoins1 } from "react-icons/ci";
// import { CiBoxes } from "react-icons/ci";
// import { CiBullhorn } from "react-icons/ci";
// import { CiCoffeeCup } from "react-icons/ci";
// import { CiDumbbell } from "react-icons/ci";
// import { CiFries } from "react-icons/ci";
// import { CiMoneyBill } from "react-icons/ci";

import { AiFillGithub } from "react-icons/ai";
// import { AiOutlineCodepen } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
// import { AiOutlineInstagram } from "react-icons/ai";

// import { MdOutlineScreenSearchDesktop } from "react-icons/md";

export const headerMenus = [
    // {
    //     title: "Youtube search",
    //     icon: <MdOutlineScreenSearchDesktop />,
    //     src: "/"
    // },
    {
        title: "추천 개발자",
        icon: <CiCoins1 />,
        src: "/developer"
    },

];

export const searchKeyword = [
    {
        title: "LLM",
        src: "/search/LLM,한국어"
    },
    {
        title: "RAG",
        src: "/search/RAG,한국어"
    },
    {
        title: "LangChain",
        src: "/search/langchain,한국어"
    },
    {
        title: "LLM 논문리뷰",
        src: "/search/논문리뷰,LLM"
    },
    {
        title: "LLM프롬프트",
        src: "/search/프롬프트 엔지니어링, 한국어"
    },
    {
        title: "Python",
        src: "/search/Python, 강의"
    },
    {
        title: "데이터분석",
        src: "/search/데이터 분석,Pandas"
    },
    {
        title: "HTML",
        src: "/search/HTML, 강의"
    },
    {
        title: "CSS",
        src: "/search/css, 강의"
    },
    {
        title: "JavaScript",
        src: "/search/javascript, 강의"
    },
    {
        title: "React.js",
        src: "/search/React.js"
    },
    {
        title: "React Native",
        src: "/search/React Native, 강의"
    },
    {
        title: "Vue.js",
        src: "/search/vue.js, 한국어"
    },
    {
        title: "Node.js",
        src: "/search/node.js"
    },
    {
        title: "SQL",
        src: "/search/SQL"
    },
    {
        title: "music",
        src: "/search/찬양, CCM"
    }
];

export const snsLink = [
    {
        title: "github",
        url: "https://github.com/arkwith7",
        icon: <AiFillGithub />
    },
    {
        title: "youtube",
        url: "https://www.youtube.com",
        icon: <AiFillYoutube />
    },

]
