import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserCircle, FaUserCheck } from 'react-icons/fa';
import { AuthContext } from '../../contexts/AuthContext'; // AuthContext imported

const Search = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [showMenu, setShowMenu] = useState(false); // Menu visibility state managed
    const navigate = useNavigate();
    const authContext = useContext(AuthContext); // contextState from AuthContext

    // console.log("Search authContext:", authContext);

    const handleSearch = () => {
        console.log(searchKeyword);
        if (searchKeyword) {
            navigate(`/search/${searchKeyword}`);
            setSearchKeyword("");
        }
    };

    const handleLoginClick = () => {
        if (!authContext || !authContext.authorize) { // Check if authorized
            navigate('/signin');
        } else {
            setShowMenu(!showMenu); // Toggle menu visibility
        }
    };

    const handleLogout = () => {
        if (authContext && authContext.onLogout) {
            authContext.onLogout();
            setShowMenu(false);
            navigate('/');
        }
    };

    if (!authContext) {
        return <div>Loading...</div>;
    }

    return (
        <div id='search'>
            <div className='search__inner'>
                <label htmlFor='searchInput'>
                    <span className='ir'>검색</span>
                </label>
                <input
                    type='search'
                    id='searchInput'
                    placeholder='Youtube 영상 검색어를 입력해주세요'
                    autoComplete="off"
                    className="search__input"
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSearch();
                        }
                    }}
                />
                <button onClick={handleLoginClick} className="login-button">
                    {authContext && authContext.authorize ?
                        <FaUserCheck size="1.5em" style={{ color: 'white' }} /> :
                        <FaUserCircle size="1.5em" style={{ color: 'grey' }} />}
                </button>
                {authContext && authContext.authorize && showMenu && (
                    <div className="dropdown-menu">
                        <ul>
                            <li onClick={handleLogout}>로그아웃</li>
                            <li onClick={() => navigate('/user-details')}>사용자정보</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Search;
